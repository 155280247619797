<template>
    <div class="flex cursor-pointer select-none"
         tabindex="0"
         @keydown.enter.prevent.stop="toggle"
         @click="toggle"
         :class="{'items-center': alignTop === false, 'flex-row-reverse justify-end': reversed, 'border rounded py-2 px-4': outlined, 'justify-between': spaced, 'bg-tangerine-light': state && onCheckTangerine, 'flex-wrap': !displayInline}"
    >
        <div v-if="rounded" class="border border-gray-400 rounded-full h-5 w-5 bg-white flex flex-none items-center justify-center mt-[0.1rem]"
             :class="{'!border-tangerine': state}">
            <Transition>
                <div class="bg-tangerine w-2 h-2 rounded-full" v-show="state"></div>
            </Transition>
        </div>

        <div v-else class="border border-gray-400 rounded w-5 h-5 bg-white flex-none" :class="{'!border-tangerine': state, 'border-red-error': errors.length > 0 || error}">
            <Transition>
                <icons-check-solid class="text-tangerine" v-show="state"></icons-check-solid>
            </Transition>
        </div>

        <div class="flex items-center" :class="{'mr-2': reversed, 'ml-2': reversed === false, 'flex-1' : separateError}">
            <slot></slot>
        </div>
        <div class="text-overline mt-1 text-red-error form-error-display w-full" v-if="showValidationErrors && errorMessage">
            {{ errorMessage}}
        </div>
    </div>

</template>

<script setup>
import {toRef, ref, reactive} from "vue";
import {useField} from "vee-validate";

const emits = defineEmits(['update:modelValue']);
const props = defineProps( {
    id: {
        type: String,
        default: "checkbox"
    },
    rounded: {
        type: Boolean,
        default: false
    },
    alignTop: {
        type: Boolean,
        default: false
    },
    reversed: {
        type: Boolean,
        default: false
    },
    outlined: {
        type: Boolean,
        default: false
    },
    spaced: {
        type: Boolean,
        default: false
    },
    onCheckTangerine: {
        type: Boolean,
        default: false
    },
    modelValue: {},
    vid: {
        type: String
    },
    rules: {
        type: [String,Object],
        default: ""
    },
    showValidationErrors: {
        type: Boolean,
        default: false,
    },
    addedClass: {
        type: String,
        default: null
    },
    displayInline: {
        type: Boolean,
        default: false
    },
    error: {
        type: Boolean,
        default: false
    },
    separateError: {
        type: Boolean,
        default: false
    }
});

const vid = toRef(props, 'vid');
const state = toRef(props, 'modelValue');

function toggle() {
    emits("update:modelValue", !state.value);
}

const {
    errorMessage,
    errors,
    meta,
} = useField(vid, props.rules, {
    initialValue: props.modelValue,
    syncVModel: true
});
</script>

<script>
export default {
    name: "CheckboxField",
}
</script>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
    transition: transform 0.1s ease;
    transform: scale(1);
}

.v-enter-from,
.v-leave-to {
    transform: scale(0);
}
</style>
